import * as React from 'react';

import s from './Billboard.module.scss';
import { Image as ImageType } from '../../types';
import classNames from 'classnames';
import Image from "../Image";


export interface BillboardProps {
    title: string;
    text?: string;
    image: ImageType;
    tag?: string;
    url?: string;
    content?: string;
    imageLeft?: boolean;
    lazy?: boolean;
}


const Billboard: React.FC<BillboardProps> = ({
    title,
    text,
    image,
    tag,
    content,
    url,
    lazy = true,
    imageLeft
}) => {
    const classes = classNames(s.Root, {
        [s.RootLinked]: !!url,
        [s.ImageLeft]: imageLeft
    });
    text = text || content;

    let mediaQueries = [
        {
            minWidth: 1440,
            src: image?.renditions.large.src,
        },
        {
            minWidth: 960,
            src: image?.renditions.medium.src,
        },
        {
            minWidth: 768,
            src: image?.renditions.large.src,
        },
        {
            minWidth: 480,
            src: image?.renditions.medium.src,
        },
    ];

    mediaQueries = mediaQueries.filter(x => x.src);

    return (
        <article className={classes}>
            {url && (
                <a href={url} className={s.Link}>
                    <span className={s.SrOnly}>{title}</span>
                </a>
            )}
            <div className={s.Content}>
                <h2 className={s.Tag}>{tag}</h2>
                <h3 className={s.Title}>{title}</h3>
                <p className={s.Text}>{text}</p>
            </div>

            {image && (
                <Image
                    image={image.renditions.small}
                    containerClass={s.ImageContainer}
                    lazy={lazy}
                    className={s.Image}
                    mediaQueries={mediaQueries}
                />
            )}
        </article>
    );
};

export default Billboard;
